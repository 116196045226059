import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import {Row, Col, Button, Form} from 'react-bootstrap'

import IconPlus from '../../assets/icon-plus.svg'
import IconRemove from '../../assets/icon-remove.svg'
import { useEffect, useState } from 'react'
import ModalResponse from '../../components/modal-response'
import imageCompression from 'browser-image-compression';

import axios from 'axios'

import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const Edit = () => {
  const [formBody, setformBody] = useState({});

  const handleFormChange = (e) => {
    const updatedFormBody = {[e.target.name] : e.target.value}  
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
  }

    const [files, setFiles] = useState([]);
    const [base64files, setBase64Files] = useState([]);
    const fileObj = [];
    const [fileArray, setFileArray] = useState([]);

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleUploadMultipleImage = async (e) => {
      fileObj.push(e.target.files)
      var newFileArray = fileArray;
      for (let i = 0; i < fileObj[0].length; i++) {
        const imageFile = fileObj[0][i];
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          newFileArray.push(URL.createObjectURL(imageFile));

          const base64 = await convertToBase64(compressedFile);
          base64files.push(base64);

        } catch (error) {
          console.log(error);
        }
      };

      setFiles(newFileArray);
      setformBody( formBody => ({
        ...formBody,
        ...{image : base64files},
      }));
    };

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [responseEvent, setResponseEvent] = useState('');

    const handleFormSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
      
      let token = sessionStorage.getItem("token");
      let headers = {
        'Authorization': token
      }

      axios.post('https://api.bioaqua.co.id/api/products', formBody, {
        headers: headers
      })
      .then(response => {
          setShowResponse(true);
          setResponseMessage('success');
          setResponseEvent('Product Added');
          window.setTimeout(function() {
              window.location = '/products';
          }, 1000);
      })
      .catch(errors => {
          setShowResponse(true);
          setResponseMessage('error');
          if( errors.response ){
            setResponseEvent(errors.response.data.message);
          }
          setSubmitting(false);
      })
    };

    const[categories, setCategories] = useState([]);

    useEffect(() => {
      let token = sessionStorage.getItem("token");

      let headers = {
        'Authorization': token
      }

      fetch(
        `https://api.bioaqua.co.id/api/category/products`,
        {
          method: "GET",
          headers: headers
        }
        )
        .then(res => res.json())
        .then(response => {
          setCategories(response);
        })
        .catch(error => console.log(error));
  }, []);

  const[submitting, setSubmitting] = useState(false);

  const handleDeleteImage = (e) => {
    var index = e.currentTarget.getAttribute('data-index');
    var newFiles = files;
    newFiles.splice(index, 1);
    setFiles(newFiles);

    var newBase64Files = base64files;
    newBase64Files.splice(index, 1);
    setBase64Files(newBase64Files);

    setformBody( formBody => ({
      ...formBody,
      ...{image : base64files},
    }));
  }

  const { quill, quillRef } = useQuill();

    useEffect(() => {
        if (quill) {
        quill.on('text-change', (delta, oldDelta, source) => {
            setformBody( formBody => ({
                ...formBody,
                ...{description : quill.root.innerHTML},
            }));
        });
        }
    }, [quill]);

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage} />
            ) : (
                <div></div>
            )
        }
        <Header page='Product Entry' user='Admin'/>
        <Row className='dashboard-content'>
          <Col md={12}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Add Product</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change' disabled={submitting}>{submitting ? 'Saving...' : 'Save Changes'}</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Category</Form.Label>
                  <Form.Select aria-label="Default select example" name='id_category' onChange={handleFormChange}>
                    <option>Select Category</option>
                    {
                      categories.map((category, index) => (
                        <option value={category.id} key={index}>{category.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name<span>(Please use different names for every product)</span></Form.Label>
                  <Form.Control type="text" placeholder="Add Product Full Name" name='name' onChange={handleFormChange} required/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Display Name<span>(Max Character: 20 Character)</span></Form.Label>
                  <Form.Control type="text" placeholder="Add Product Display Name" name='display_name' onChange={handleFormChange} required/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Tagline</Form.Label>
                  <Form.Control type="text" placeholder="Add Product Tagline" name='tagline' onChange={handleFormChange} required/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Description</Form.Label>
                    <div style={{ width: '100%', height: 300 }}>
                        <div ref={quillRef} />
                    </div>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Upload Image<span>(Recommended Resolution: 1080x1920; Must Be PNG (Transparent); Max File Size: 5MB)</span></Form.Label>
                  <Row>
                  {
                    files.map((file, key) => (
                      <Col md={2} key={key}>
                        <img src={file} alt="..." className='upload-image-preview'/>
                        <Button data-index={key} className='delete-image-preview' onClick={handleDeleteImage}><img src={IconRemove} className='delete-preview-btn'/></Button>
                      </Col>
                    ))
                  }
                  <Col>
                  <label htmlFor='form-image' className='label-image'>
                      <img src={IconPlus} />
                  </label>
                  </Col>
                  </Row>
                  <input id='form-image' type="file" hidden multiple onChange={handleUploadMultipleImage}/>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                      <Form.Label className='content-form-label'>Price</Form.Label>
                      <Form.Control type="number" placeholder="Add Price" name='price' onChange={handleFormChange} required/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                      <Form.Label className='content-form-label'>Discount<span>(optional)</span></Form.Label>
                      <Form.Control type="number" placeholder="Add Discount (ex: 60)" name='discount' onChange={handleFormChange} required/>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Shopee Link<span>(optional)</span></Form.Label>
                  <Form.Control type="text" name='link_shopee' onChange={handleFormChange} placeholder='add Shopee Link' required/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Tokopedia Link<span>(optional)</span></Form.Label>
                  <Form.Control type="text" name='link_tokopedia' onChange={handleFormChange} placeholder='add Tokopedia Link' required/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>TikTok Link<span>(optional)</span></Form.Label>
                  <Form.Control type="text" name='link_tiktok' onChange={handleFormChange} placeholder='add TikTok Link' required/>
                </Form.Group>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Edit;