import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import axios from 'axios'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'
import Pagination from '../../components/pagination'

import EditIcon from '../../assets/icon-pencil.svg'

let PageSize = 5;

const Home = () => {
  const [categories, setCategory] = useState([]);
  const [publishedStatus, setPublishedStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    fetch(
      `https://api.bioaqua.co.id/api/category/products?admin=Y`,
      {
        method: "GET",
        headers: headers
      }
      )
      .then(res => res.json())
      .then(response => {
        setCategory(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(categories.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempCategory = currentData.map((category) => {
        category.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...category, isChecked: checked };
      });
      setCurrentData(tempCategory);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempCategory = currentData.map((category) =>
        category.id == id ? { ...category, isChecked: checked } : category
      );
      setCurrentData(tempCategory);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((category) => {
          if(category.isChecked) {
            selectedId.push(category.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published Product Category');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished Product Category');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }

      let token = sessionStorage.getItem("token");
      let headers = {
        'Authorization': token
      }

      axios.patch('https://api.bioaqua.co.id/api/category/products/publish', publishBody, {
        headers: headers
      })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/category';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((category) => {
        if(category.isChecked) {
          selectedId.push(category.id)
        }
    });

    setResponseEvent('Deleted Product Category');
    deleteBody.data = selectedId;

    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }
    
    axios.patch('https://api.bioaqua.co.id/api/category/products/delete', deleteBody, {
      headers:headers
    })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/category';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Product Category' user='Admin'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Product Category</h4></Col>
              {currentData.some((category) => category?.isChecked)?(
                <Col className='content-header-btn-action'>
                  {
                    publishedStatus ? (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                    ) : (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                    )
                  }
                  <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ) : (
                <Col className='content-header-btn-action'>
                  <a href='/category/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
                </Col>
              )}
          </Row>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((category) => category?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((category) => category?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Id</Col>
                <Col>Name</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {
            currentData.length <= 0 ? (
              <>
                <center className='pt-2'><p>There is no data</p></center>
              </>
            ):(
              <>
              </>
            )
          }
          {currentData.map((category, index) => (
            <Row className={`${category?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`} 
            key={index}>
              <Col md={1}><input
              type="checkbox"
              className={`${category?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={category.id}
              published={category.is_published}
              checked={category?.isChecked || false}
              onChange={handleSelectBox}
              /></Col>
              <Col>{category.id}</Col>
              <Col>{category.name}</Col>
              <Col>{moment(category.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(category.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              category.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><a href={'/category/edit/' + category.id}><img src={EditIcon} /></a></Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={categories.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
