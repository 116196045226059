export const ContentManagement = [
    {
        title : "Main Banner",
        link: "/banner"
    },
    {
        title : "Product Category",
        link: "/category"
    },
    {
        title : "Product Entry",
        link: "/products"
    },
    {
        title : "Store Locations",
        link: "/stores"
    },
    {
        title : "Social Media",
        link: "/social-media"
    },
]

export const ConsultationsRoom = [
    {
        title: "Tickets",
        link: "/tickets"
    }
]

export const UserManagement = [
    {
        title: "Internal Members",
        link: "/members"
    },
    {
        title: "Users",
        link: "/users"
    }
]

export const ArticleManagement = [
    {
        title: "Posts",
        link: "/article/post"
    },
    {
        title: "Category",
        link: "/article/category"
    },
    {
        title: "Tags",
        link: "/article/tags"
    }
]