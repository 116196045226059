import {Row, Col, Button, Form} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import axios from 'axios'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'
import Pagination from '../../components/pagination'

import EditIcon from '../../assets/icon-pencil.svg'

let PageSize = 5;

const Home = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [banner, setBanner] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [publishedStatus, setPublishedStatus] = useState(true);

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    fetch(
      `https://api.bioaqua.co.id/api/banner/home?admin=Y`,
      {
        method: "GET",
        headers: headers
      }
      )
      .then(res => res.json())
      .then(response => {
        setBanner(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(banner.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempBanner = currentData.map((ban) => {
        ban.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...ban, isChecked: checked };
      });
      setCurrentData(tempBanner);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempBanner = currentData.map((ban) =>
        ban.id == id ? { ...ban, isChecked: checked } : ban
      );
      setCurrentData(tempBanner);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((ban) => {
          if(ban.isChecked) {
            selectedId.push(ban.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published banner');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished banner');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }


      let token = sessionStorage.getItem("token");

      let headers = {
        'Authorization': token
      }

      axios.patch('https://api.bioaqua.co.id/api/banner/home/publish', publishBody, {
        headers: headers
      })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/banner';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((ban) => {
        if(ban.isChecked) {
          selectedId.push(ban.id)
        }
    });

    setResponseEvent('Deleted banner');
    deleteBody.data = selectedId;

    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    axios.patch('https://api.bioaqua.co.id/api/banner/home/delete', deleteBody, {
      headers: headers
    })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/banner';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleApplyFilter = (e) => {
    let value = e.target.value;
    if(value=='0') {
      setCurrentData(banner.slice(0, PageSize));
      setCurrentPage(1);
      return
    }

    let newData = [];
    banner.forEach((ban) => {
      if(ban.device_type.toString() == value) {
        newData.push(ban)
      }
    })
    setCurrentData(newData.slice(0, PageSize));
    setCurrentPage(1);
  }
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Home Banner' user='Admin'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Home Banner</h4></Col>
              {currentData.some((ban) => ban?.isChecked)?(
                <Col className='content-header-btn-action'>
                  {
                    publishedStatus ? (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                    ) : (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                    )
                  }
                  <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ) : (
                <Col className='content-header-btn-action'>
                  <a href='/banner/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
                </Col>
              )}
          </Row>
        </div>
        <div className='dashboard-filter'>
          <Form.Select className='dashboard-filter-select' aria-label="Default select example" onChange={handleApplyFilter}>
            <option value="0">All</option>
            <option value="1">Desktop</option>
            <option value="2">Mobile</option>
          </Form.Select>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((ban) => ban?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((ban) => ban?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Image</Col>
                <Col>Device Type</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {
            currentData.length <= 0 ? (
              <>
                <center className='pt-2'><p>There is no data</p></center>
              </>
            ):(
              <>
              </>
            )
          }
          {currentData.map((ban, index) => (
            <Row className={`${ban?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`} 
            key={index}>
              <Col md={1}><input
              type="checkbox"
              className={`${ban?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={ban.id}
              published={ban.is_published}
              checked={ban?.isChecked || false}
              onChange={handleSelectBox}
              /></Col>
              <Col><img className='row-data-image' src={'https://api.bioaqua.co.id/banner/'+ban.image} /></Col>
              <Col>{ban.device_type == 2 ? ('Mobile') : ('Desktop')}</Col>
              <Col>{moment(ban.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(ban.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              ban.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><a href={'/banner/edit/' + ban.id}><img src={EditIcon} /></a></Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={banner.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
