import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState } from "react";
import ModalResponse from '../../components/modal-response'
import moment from 'moment'
import axios from 'axios'

import EditIcon from '../../assets/icon-pencil.svg'

const Home = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.bioaqua.co.id/api/users/internal`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setMembers(response.data);
      })
      .catch(error => console.log(error));
  }, []);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempMembers = members.map((member) => {
        return { ...member, isChecked: checked };
      });
      setMembers(tempMembers);
    } else {
      let tempMembers = members.map((member) =>
        member.id == id ? { ...member, isChecked: checked } : member
      );
      setMembers(tempMembers);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    members.map((member) => {
        if(member.isChecked) {
          selectedId.push(member.id)
        }
    });

    setResponseEvent('Deleted Member');
    deleteBody.data = selectedId;
    axios.post('https://api.bioaqua.co.id/api/users/internal/list/delete', deleteBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/members';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Member' user='Diana Prince'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Internal Members</h4></Col>
            <Col className='content-header-btn-action'>
              {Array.isArray(members) && members.some((member) => member?.isChecked)? (
                <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
              ): (
                <a href='/members/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
              )}
              
            </Col>
          </Row>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
              <Col md={1}><input
                type="checkbox"
                className={`${members.every((member) => member?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!members.some((member) => member?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Name</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {Array.isArray(members) && members.map((member, index) => (
            <Row className={`${member?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`}>
              <Col md={1}><input
              type="checkbox"
              className={`${member?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={member.id}
              checked={member?.isChecked || false}
              onChange={handleSelectBox}
              key={index}
              /></Col>
              <Col>{member.name}</Col>
              <Col>{moment(member.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(member.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1} className='row-btn-section'><a href={'/members/edit/' + member.id}><img src={EditIcon} /></a></Col>
            </Row>
          ))}
          {
            members.length <= 0 ? (
              <>
                <center className='pt-2'><p>There is no data</p></center>
              </>
            ):(
              <>
              </>
            )
          }
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
