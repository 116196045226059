import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState } from "react"
import moment from 'moment'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'

import backBtn from '../../assets/back-button.svg'

const Home = () => {
  const { id } = useParams();
  const [ticket, setTicket] = useState({})
  const [chats, setChats] = useState([])

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    fetch(
        `https://api.bioaqua.co.id/api/tickets/`+id,
        {
          method: "GET",
          headers: headers
        }
        )
        .then(res => res.json())
        .then(response => {
          setTicket(response);
        })
        .catch(error => console.log(error));

    fetch(
        `https://api.bioaqua.co.id/api/tickets/chats/`+id,
        {
          method: "GET",
          headers: headers
        }
    )
    .then(res => res.json())
    .then(response => {
        setChats(response);
    })
    .catch(error => console.log(error));
  }, []);

  const handleSubmitMessageEnter = (e) => {
    if(e.key === "Enter"){
        let contenteditable = document.querySelector('[contenteditable]'),
        text = contenteditable.textContent;
        e.preventDefault();
        document.querySelector('[contenteditable]').innerHTML = '';

        let formBody = {
            'id_tickets': id,
            'text': text,
            'source': 1
        }

        let token = sessionStorage.getItem("token");
        let headers = {
            'Authorization': token
        }

        axios.patch('https://api.bioaqua.co.id/api/tickets/chat', formBody, {
            headers: headers
        })
        .then(response => {
            window.location.reload();
        })
        .catch(errors => {
            console.log(errors)
        })
    }
  }

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Tickets' user='Admin'/>
        <div className='tickets-rooms-header'>
            <Row>
                <Col md={1} className='tickets-rooms-back-section'>
                    <a href='/tickets'>
                        <Button className='tickets-rooms-back-btn'>
                            <img src={backBtn} className='tickets-rooms-back-btn-img' />
                        </Button>
                    </a>
                </Col>
                <Col>
                    <div className='tickets-rooms-user-info'>
                        <h4>{ticket.user_name} <span className='tickets-rooms-user-info-email'>({ticket.user_email})</span></h4>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='tickets-rooms-chatbox'>
            <div className='tickets-rooms-chatbox-container'>
                <ul className='tickets-chats-chatbox-ul'>
                {
                    chats.map((chat, index) => (
                        <li key={index} className='tickets-chats-chatbox-li'>
                        <div className={`${chat.source == 1? 'tickets-chats-message-admin' : 'tickets-chats-message-user'}`}>
                            <div className={`tickets-chats-bubble ${chat.source == 1? 'tickets-chats-bubble-admin' : 'tickets-chats-bubble-user'}`}>
                                <p>{chat.text}</p>
                                <p className='tickets-chats-time'>{moment(chat.created_at).utc().format("D MMM YYYY - hh:mm A")}</p>
                            </div>
                        </div>
                        </li>
                    ))
                }
                </ul>
            </div>
        </div>
        <div 
            className="tickets-rooms-chat-input-text" 
            contentEditable
            placeholder="Send Message..."
            onKeyDown={handleSubmitMessageEnter}
            >
        </div>
      </div>
    </>
  );
}

export default Home;
