import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import moment from 'moment'

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Pagination from '../../components/pagination'

let PageSize = 5;

const Home = () => {
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    fetch(
      `https://api.bioaqua.co.id/api/tickets`,
      {
        method: "GET",
        headers: headers
      }
      )
      .then(res => res.json())
      .then(response => {
        setTickets(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(tickets.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Tickets' user='Admin'/>
        <div className='dashboard-content'>
            <h4 className='content-header'>Consultations Room</h4>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col>Requester</Col>
                <Col>Subject</Col>
                <Col md={2}>Status</Col>
                <Col>Date</Col>
                <Col md={2} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {
            currentData.length <= 0 ? (
              <>
                <center className='pt-2'><p>There is no data</p></center>
              </>
            ):(
              <>
              </>
            )
          }
          {currentData.map((ticket, index) => (
            <Row className={`mb-3 row-list-per-data`} 
            key={index}>
              <Col>{ticket.user_name}</Col>
              <Col className='row-list-ticket-subject'>{ticket.subject}</Col>
              <Col md={2}>{
                ticket.status == 2 ? (
                  <span className='row-list-ticket-status-solved'>Solved</span>
                ) : ticket.status == 1 ? (
                  <span className='row-list-ticket-status-pending'>Pending</span>
                )  : (
                  <span className='row-list-ticket-status-new'>New</span>
                )
              }</Col>
              <Col>{moment(ticket.created_at).utc().format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={2} className='row-btn-section'>
                <a href={'/tickets/room/' + ticket.id}>
                    <Button variant='light' className='row-btn-details'>View Details</Button>
                </a>
              </Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={tickets.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

export default Home;
