import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import {Row, Col, Button, Form} from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios'
import ModalResponse from '../../components/modal-response'

const Add = () => {
    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios.post('https://api.bioaqua.co.id/api/users/internal', formBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/members';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
    }

    const [passwordMatch, setPasswordMatch] = useState(false);

    const handleConfirmationPassword = (e) => {
        if(e.target.value === document.getElementById("password-field").value) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    };

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Member Added' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Member' user='Diana Prince'/>
        <Row className='dashboard-content'>
          <Col md={12}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Add Member</h4>
                </Col>
                <Col className='content-header-btn-action'>
                    {
                        passwordMatch ? (
                            <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                        ) : (
                            <Button type='submit' variant='warning' className='header-btn-action-change' disabled>Save Changes</Button>
                        )
                    }
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Input Your Name" name='name' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Email</Form.Label>
                  <Form.Control type="text" placeholder="Input Your Email" name='email' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Password</Form.Label>
                  <Form.Control id="password-field" type="password" placeholder="Input Your Password" name='password' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Confirmation Password</Form.Label>
                  <Form.Control type="password" placeholder="Input Your Confirmation Password" onChange={handleConfirmationPassword}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Add;