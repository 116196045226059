import {Row, Col, Button, Modal, Form} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import axios from 'axios'

import Sidebar from '../../../components/sidebar'
import Header from '../../../components/header'
import ModalResponse from '../../../components/modal-response'
import Pagination from '../../../components/pagination'

import EditIcon from '../../../assets/icon-pencil.svg'

let PageSize = 5;

const Home = () => {
  const [tags, setTags] = useState([]);
  const [publishedStatus, setPublishedStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.bioaqua.co.id/api/article/tags`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setTags(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(tags.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempTags = currentData.map((tags) => {
        tags.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...tags, isChecked: checked };
      });
      setCurrentData(tempTags);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempTags = currentData.map((tags) =>
      tags.id == id ? { ...tags, isChecked: checked } : tags
      );
      setCurrentData(tempTags);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((tags) => {
          if(tags.isChecked) {
            selectedId.push(tags.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published Tags');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished Tags');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }
      let token = sessionStorage.getItem("token");
      let headers = {
        'Authorization': token
      }
      axios.post('https://api.bioaqua.co.id/api/article/tags/publish', publishBody, {
        headers: headers
      })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/tags';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((tags) => {
        if(tags.isChecked) {
          selectedId.push(tags.id)
        }
    });

    setResponseEvent('Deleted Tags');
    deleteBody.data = selectedId;
    let token = sessionStorage.getItem("token");
    let headers = {
      'Authorization': token
    }

    axios.post('https://api.bioaqua.co.id/api/article/tags/delete', deleteBody, {
      headers: headers
    })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/tags';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const [formBody, setformBody] = useState({});
  const handleFormChange = (e) => {
    const updatedFormBody = {[e.target.name] : e.target.value}  
    setformBody( formBody => ({
        ...formBody,
        ...updatedFormBody,
    }));
    setDetailTags( detailTags => ({
        ...detailTags,
        ...updatedFormBody,
    }))
  }

  const [modalType, setModalType] = useState('')

  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false)

  const handleshowAddModal = (e) => {
    setModalType('add')
    setshowAddModal(true)
  }

  const handleEditTagsModal = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    let detail = currentData.find(o => o.id == id)
    setDetailTags(detail)
    setModalType('edit')
    setshowAddModal(true);
  }

  const handleSubmitAddTags = (e) => {
    setResponseEvent('Articles Tags Added');
    e.preventDefault();
    axios.post('https://api.bioaqua.co.id/api/article/tags', formBody)
      .then(response => {
          setShowResponse(true);
          setResponseMessage('success');
          window.setTimeout(function() {
             window.location = '/article/tags';
          }, 700);
      })
      .catch(errors => {
          setShowResponse(true);
          setResponseMessage('error');
      })
  }

  const[detailTags,setDetailTags] = useState({})

  const handleSubmitEditTags = (e) => {
    setResponseEvent('Articles Tags Edited');
    e.preventDefault();
    let body = {
        name: detailTags.name
    }
    axios.put('https://api.bioaqua.co.id/api/article/tags/'+detailTags.id, body)
      .then(response => {
          setShowResponse(true);
          setResponseMessage('success');
          window.setTimeout(function() {
             window.location = '/article/tags';
          }, 700);
      })
      .catch(errors => {
          setShowResponse(true);
          setResponseMessage('error');
      })
  }
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Article Tags' user='Diana Prince'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Article Tags</h4></Col>
              {currentData.some((tags) => tags?.isChecked)?(
                <Col className='content-header-btn-action'>
                  {
                    publishedStatus ? (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                    ) : (
                      <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                    )
                  }
                  <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ) : (
                <Col className='content-header-btn-action'>
                 <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleshowAddModal}>Add</Button>
                </Col>
              )}
          </Row>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((tags) => tags?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((tags) => tags?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Name</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {currentData.length != 0 ? currentData.map((tags, index) => (
            <Row className={`${tags?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`} 
            key={index}>
              <Col md={1}><input
              type="checkbox"
              className={`${tags?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={tags.id}
              published={tags.is_published}
              checked={tags?.isChecked || false}
              onChange={handleSelectBox}
              /></Col>
              <Col>{tags.name}</Col>
              <Col>{moment(tags.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(tags.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              tags.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><Button data-id={tags.id} onClick={handleEditTagsModal} className='edit-data-btn' variant='light' size="sm"><img src={EditIcon} /></Button></Col>
            </Row>
          )): (
            <>
                <center><p>There is no data</p></center>
            </>
          )}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={tags.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Form onSubmit={ modalType == 'add' ? handleSubmitAddTags : handleSubmitEditTags}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Article Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Tags name</Form.Label>
                <Form.Control name='name' type="text" placeholder="Input Tags Name" onChange={handleFormChange} value={ modalType == 'edit' ? detailTags.name : null} />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className='header-btn-action-publish' onClick={handleCloseAddModal}>
              Close
            </Button>
            <Button type='submit' variant="warning" className='header-btn-action-change'>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Home;
