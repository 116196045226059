import React from 'react'
import moment from 'moment'
import {Container, Row, Col} from 'react-bootstrap'
import CheckIcon from '../../assets/icon-check.svg'
import CheckIconGray from '../../assets/icon-check-gray.svg'

const Home = ({created, updated, dataid, updatedby, ispublished}) => {
  return (
    <>
        <div className='status-section'>
            <Container>
                <div className='status-header'>
                    <p className='status-title'>Status</p>
                    {ispublished == 1 ? (
                        <p className='status-header-stat'>Published <span><img src={CheckIcon} /></span></p>
                    ) : (
                        <p className='status-header-stat'>Unpublished <span><img src={CheckIconGray} /></span></p>
                    )}
                </div>
                <div className='status-main'>
                    <div className='status-main-section'>
                        <p className='status-title'>Data ID</p>
                        <p className='status-main-stat'>{dataid}</p>
                    </div>
                    <div className='status-main-section'>
                        <p className='status-title'>Created Date</p>
                        <p className='status-main-stat'>{moment(created).format("D MMM YYYY - hh:mm A")}</p>
                    </div>
                    <div className='status-main-section'>
                        <p className='status-title'>Last Saved</p>
                        <p className='status-main-stat'>{moment(updated).format("D MMM YYYY - hh:mm A")}</p>
                    </div>
                    <div className='status-main-section'>
                        <p className='status-title'>Author</p>
                        <p className='status-main-stat'>{updatedby}</p>
                    </div>
                </div>
            </Container>
        </div>
    </>
  );
}

export default Home;
