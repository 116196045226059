import {Row, Col, Button, Dropdown, Modal, ProgressBar, Form} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react"
import axios from 'axios'
import moment from 'moment'

import Papa from "papaparse";

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'
import Pagination from '../../components/pagination'

import EditIcon from '../../assets/icon-pencil.svg'

import CSVFormat from '../../files/products_bulk_upload.csv'

let PageSize = 8;

const Home = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    fetch(
      `https://api.bioaqua.co.id/api/products?admin=Y`,
      {
        method: "GET",
        headers: headers
      }
      )
      .then(res => res.json())
      .then(response => {
        setProducts(response);
        setCurrentData(response.slice(0, PageSize));
      })
      .catch(error => console.log(error));
      setCurrentPage(1);

    fetch(
      `https://api.bioaqua.co.id/api/category/products?admin=Y`, 
      {
        method: "GET",
        headers: headers
      }
    )
    .then(res => res.json())
    .then(response => {
      setCategories(response);
    })
    .catch(error => console.log(error));
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(products.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const [publishedStatus, setPublishedStatus] = useState(true);

  const handleSelectBox = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempproduct = currentData.map((product) => {
        product.is_published == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
        return { ...product, isChecked: checked };
      });
      setCurrentData(tempproduct);
    } else {
      e.target.getAttribute('published') == 1 ? setPublishedStatus(true) : setPublishedStatus(false);
      let tempproduct = currentData.map((product) =>
        product.id == id ? { ...product, isChecked: checked } : product
      );
      setCurrentData(tempproduct);
    }
  };

  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseEvent, setResponseEvent] = useState('');

  const handlePublish = (e) => {
      const publishBody = {};
      const selectedId = [];

      currentData.map((product) => {
          if(product.isChecked) {
            selectedId.push(product.id)
          }
      });
      
      if(e.target.getAttribute('action') == 'publish') {
        setResponseEvent('Published product');
        publishBody.data = selectedId;
        publishBody.published = 1;
      } else {
        setResponseEvent('Unpublished product');
        publishBody.data = selectedId;
        publishBody.published = 0;
      }

      let token = sessionStorage.getItem("token");

      let headers = {
        'Authorization': token
      }

      axios.patch('https://api.bioaqua.co.id/api/products/publish', publishBody, {
        headers: headers
      })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/products';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const handleDelete = () => {
    const selectedId = [];
    const deleteBody = {};
    
    currentData.map((product) => {
        if(product.isChecked) {
          selectedId.push(product.id)
        }
    });

    setResponseEvent('Deleted Product');
    deleteBody.data = selectedId;

    let token = sessionStorage.getItem("token");

    let headers = {
      'Authorization': token
    }

    axios.patch('https://api.bioaqua.co.id/api/products/delete', deleteBody, {
      headers: headers
    })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/products';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
  };

  const [showUpload, setShowUpload] = useState(false);

  const handleCloseUpload = () => {
    setShowUpload(false)
    window.location.reload()
  };
  const handleShowUpload = () => setShowUpload(true);

  const handleUploadCSV = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        comments: "#",
        complete: function(results) {
          setBulkData(results.data)
        }}
      )
      setCsvFiles(files[0].name)
    }
  }

  const [csvFile, setCsvFiles] = useState('');

  const [bulkData, setBulkData] = useState([])
  const [isSubmit, setIsSubmit] = useState(false);
  const [successTotal, setSuccessTotal] = useState(0);

  const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    })  
  })

  const manyImageToBase64 = async (image) => {
    let imgs = []
    for (let img of image) {
      let base64Img = await toDataURL(img).then(result => imgs.push(result))
    }
    return imgs
  }

  const handleBulkSubmit = async () => {
    setIsSubmit(true);
    let totalData = bulkData.length;
    let clearTotal = 0;

    for (let data of bulkData) {
      let token = sessionStorage.getItem("token");
      let headers = {
        'Authorization': token
      }
      if (data.image.includes('|')) {
        let images = data.image.split('|')
        data.image = await manyImageToBase64(images)
        axios.post('https://api.bioaqua.co.id/api/products', data, {
          headers: headers
        })
        .catch(errors => {
            console.log('error');
            console.log(errors)
        })
      } else {
        toDataURL(data.image)
        .then(dataUrl => {
          data.image = [dataUrl]
          axios.post('https://api.bioaqua.co.id/api/products', data, {
            headers: headers
          })
          .catch(errors => {
              console.log('error');
              console.log(errors)
          })
        })
      }

      clearTotal = clearTotal + 1
      setSuccessTotal(clearTotal/totalData)
    }

    setSuccessTotal(100)
  }

  const handleApplyFilter = (e) => {
    let value = e.target.value;
    if(value=='0') {
      setCurrentData(products.slice(0, PageSize));
      setCurrentPage(1);
      return
    }

    let newData = [];
    products.forEach((prod) => {
      if(prod.id_category == value) {
        newData.push(prod)
      }
    })
    setCurrentData(newData.slice(0, PageSize));
    setCurrentPage(1);
  }
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event={responseEvent} response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Product Entry' user='Admin'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Product Entry</h4></Col>
              {currentData.some((product) => product?.isChecked)? (
                <Col className='content-header-btn-action'>
                {
                  publishedStatus ? (
                    <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='unpublish'>Unpublish</Button>
                  ) : (
                    <Button variant='secondary' className='me-3 header-btn-action-publish' onClick={handlePublish} action='publish'>Publish</Button>
                  )
                }
                <Button variant='warning' className='me-3 header-btn-action-change' onClick={handleDelete}>Delete</Button>
                </Col>
              ): (
                <Col className='content-header-btn-action'>
                  <Row>
                    <Col md={9}>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" className='header-btn-action-upload' id="dropdown-basic">
                          Bulk Upload
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href={CSVFormat} download="productsUploadFormat" target='_blank'>Download Format</Dropdown.Item>
                          <Dropdown.Item href='#' onClick={handleShowUpload}>Upload CSV</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <a href='/products/add'><Button variant='warning' className='me-3 header-btn-action-change'>Add</Button></a>
                    </Col>
                  </Row>
                </Col>
              )}
          </Row>
        </div>
        <div className='dashboard-filter'>
          <Form.Select className='dashboard-filter-select' aria-label="Default select example" onChange={handleApplyFilter}>
            <option value="0">All</option>
            {
              categories &&
                categories.map((value, index) => (
                  <option value={value.id} key={index}>{value.name}</option>
                ))
            }
          </Form.Select>
        </div>
        <div className='row-data'>
          <div className='row-header mb-4'>
            <Row>
                <Col md={1}><input
                type="checkbox"
                className={`${currentData.every((product) => product?.isChecked)? 'checkbox-checked' : '' } form-check-input`}
                id="allSelect"
                checked={!currentData.some((product) => product?.isChecked !== true)}
                onChange={handleSelectBox}
                /></Col>
                <Col>Name</Col>
                <Col>Category</Col>
                <Col>Created Date</Col>
                <Col>Last Modified</Col>
                <Col md={1}>Status</Col>
                <Col md={1} className='row-btn-section'>&nbsp;</Col>
            </Row>
          </div>
          <div className='row-list'>
          {
            currentData.length <= 0 ? (
              <>
                <center className='pt-2'><p>There is no data</p></center>
              </>
            ):(
              <>
              </>
            )
          }
          {currentData.map((product, index) => (
            <Row className={`${product?.isChecked ? 'row-checked' : ''} mb-3 row-list-per-data`} key={index}>
              <Col md={1}><input
              type="checkbox"
              className={`${product?.isChecked ? 'checkbox-checked' : ''} form-check-input checkbox-per-data`}
              id={product.id}
              published={product.is_published}
              checked={product?.isChecked || false}
              onChange={handleSelectBox}
              key={index}
              /></Col>
              <Col>{product.display_name}</Col>
              <Col>{product.category}</Col>
              <Col>{moment(product.created_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col>{moment(product.updated_at).format("D MMM YYYY - hh:mm A")}</Col>
              <Col md={1}>{
              product.is_published == 1 ? 'Published' : 'Unpublished'
              }</Col>
              <Col md={1} className='row-btn-section'><a href={'/products/edit/' + product.id}><img src={EditIcon} /></a></Col>
            </Row>
          ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={products.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
      <Modal show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor='input-csv' className='label-input-csv'>
              {
                csvFile != '' ? (
                  <>
                    <img className='label-input-csv-image' src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/826px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png' />
                    <p>{csvFile}</p>
                  </>
                ) : (
                  <>
                    Upload CSV
                  </>
                )
              }
          </label>
          <input
            id='input-csv'
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={handleUploadCSV}
          />
          {
            isSubmit ? (
              <>
                <div className='input-csv-progress-message'>
                  <p>Uploading In Process...</p>
                  {
                    successTotal >= 100 ? (
                      <p>Done. Product Uploaded Succesfully</p>
                    ) : (<></>)
                  }
                </div>
                <ProgressBar variant='success' animated now={successTotal} />
              </>
            ) : (
              <></>
            )
          }
          {
            successTotal >= 100 ? (
              <Button variant='success' className='input-csv-btn' onClick={handleCloseUpload}>Close</Button>
            ) : (
              <Button variant='success' className='input-csv-btn' onClick={handleBulkSubmit}>Submit</Button>
            )
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
