import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import CheckIcon from '../../assets/icon-check.svg'
import ExclamationIcon from '../../assets/icon-exclamation.svg'

const Home = ({response, event}) => {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    return (
        <>
            {
                response == "success" ? (
                    <Modal show={show} className='modal-response'>
                        <Modal.Body className='modal-response-body'>
                            <img src={CheckIcon} />
                            <h2>Success</h2>
                            <p>{event} Successfully</p>
                        </Modal.Body>
                    </Modal>
                ) : (
                    <Modal show={show} className='modal-response'>
                        <Modal.Body className='modal-response-body'>
                            <img src={ExclamationIcon} />
                            <h2>Error</h2>
                            {
                                event ? (
                                    <p>Error Occured: {event}</p>

                                ) : (
                                    <p>There is Something Wrong with the Server Please Try Again Later</p>
                                )
                            }
                            <Button className='header-btn-action-change' variant="warning" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Body>
                    </Modal>
                )
            }
            
        </>
    );
}

export default Home;
