import { Container, Form, Button } from 'react-bootstrap';

import mainlogo from '../../assets/main-logo-blue.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import { useState, useEffect } from 'react'

function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    
    const formBody = {
      'email' : email,
      'password': password
    }

    axios.post('https://api.bioaqua.co.id/api/auth/token', formBody)
      .then(response => {
        sessionStorage.setItem('token_expired', response.data.expired_at);
        sessionStorage.setItem('token', response.data.token);
        window.location = '/banner';
      })
      .catch(errors => {
        console.log(errors)
      })
  }

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if(token != "" && token != null) {
      window.location = '/banner';
    }
  }, []);

  return (
      <div className='loginpage'>
        <img src={mainlogo} />
        <Container className='loginbox'>
          <div className='loginheader'>
            <h1>Login</h1>
            <p>Login using your credential</p>
          </div>
          <Form className='loginform' onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className='loginform-label'>Email</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" className='logininput' name='email' onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className='loginform-label'>Password</Form.Label>
              <Form.Control type="password" placeholder="password" className='logininput' name='password' onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Button type='submit' className='loginbtn' variant='dark'>Login</Button>
          </Form>
        </Container>
      </div>
  );
}

export default Login;
