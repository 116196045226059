import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Login,
  Banner,
  BannerAdd,
  BannerEdit,
  Category,
  CategoryAdd,
  CategoryEdit,
  Product,
  ProductAdd,
  ProductEdit,
  Users,
  Stores,
  StoresAdd,
  StoresEdit,
  Members,
  MembersAdd,
  MembersEdit,
  Tickets,
  TicketsRooms,
  ArticleCategory,
  ArticleTags,
  ArticlePost,
  ArticlePostForm,
  SocialMedia
} from "./pages";
import { useEffect, useState } from 'react';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import 'bootstrap/dist/css/bootstrap.min.css'

function getTokenSession() {
  const savedToken = sessionStorage.getItem('token');
  return savedToken
}

function App() {
  const token = getTokenSession();

  if(!token) {
    return <Login />
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/banner/add" element={<BannerAdd />} />
          <Route path="/banner/edit/:id" element={<BannerEdit />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/add" element={<CategoryAdd />} />
          <Route path="/category/edit/:id" element={<CategoryEdit />} />
          <Route path="/products" element={<Product />} />
          <Route path="/products/add" element={<ProductAdd />} />
          <Route path="/products/edit/:id" element={<ProductEdit />} />
          <Route path="/users" element={<Users />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/stores/add" element={<StoresAdd />} />
          <Route path="/stores/edit/:id" element={<StoresEdit />} />
          <Route path="/members" element={<Members />} />
          <Route path="/members/add" element={<MembersAdd />} />
          <Route path="/members/edit/:id" element={<MembersEdit />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/tickets/room/:id" element={<TicketsRooms />} />
          <Route path="/article/category" element={<ArticleCategory />} />
          <Route path="/article/tags" element={<ArticleTags />} />
          <Route path="/article/post" element={<ArticlePost />} />
          <Route path="/article/post/add" element={<ArticlePostForm />} />
          <Route path="/article/post/edit/:id" element={<ArticlePostForm />} />
          <Route path="/social-media" element={<SocialMedia />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
