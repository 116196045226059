import {Row, Col, Button} from 'react-bootstrap'
import React, { useEffect, useState } from "react"
import axios from 'axios'

import { useSearchParams } from "react-router-dom"

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tiktok, setTiktok] = useState();
  const [ig, setIg] = useState();

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    let headers = {
      'Authorization': token
    }

    let code = searchParams.get("code");
    if(code != '' && code != undefined) {
      let source = searchParams.get("source")
      if(source == null) {
        source = 'ig';
      }
      axios.get("https://api.bioaqua.co.id/api/social-media/account/token?media="+source+"&code="+code, {
        headers: headers
      })
      .then(response => {
        let body = {}
        if(source == 'tiktok') {
          body = {
            access_token: response.data.data.access_token,
            refresh_expires_in: response.data.data.refresh_expires_in,
            refresh_token: response.data.data.refresh_token,
            expires_in: response.data.data.expires_in,
            source: source
          }
        }
        if (source == 'ig' ) {
          console.log(response.data)
          body = {
            access_token: response.data.data.access_token,
            refresh_expires_in: response.data.data.expires_in,
            refresh_token: '',
            expires_in: response.data.data.expires_in,
            source: source
          }
        }
        else {
          console.log("errors: source not found")
          return
        }

        axios.put('https://api.bioaqua.co.id/api/social-media', body, {
          headers: headers
        })
        .then(response => {
          window.location = '/social-media'
        })
        .catch(errors => {
          console.log(errors)
        })
      })
      .catch(errors => {
        console.log(errors)
      })
    }

    axios.get("https://api.bioaqua.co.id/api/social-media?admin=Y&media=tiktok", {
      headers: headers
    })
    .then(response => {
      setTiktok(response.data.data)
    })
    .catch(errors => {
      console.log(errors)
    })

    axios.get("https://api.bioaqua.co.id/api/social-media?admin=Y&media=ig", {
      headers: headers
    })
    .then(response => {
      setIg(response.data.data)
    })
    .catch(errors => {
      console.log(errors)
    })
  
  }, [])

  const handleTiktokChangeAccount = () => {
    let token = sessionStorage.getItem("token");
    let headers = {
      'Authorization': token
    }

    let media = 'tiktok'
    let scope = 'user.info.basic,video.list'
    let redirectUri = 'https://dashboard.bioaqua.co.id/social-media?source=tiktok'

    axios.get('https://api.bioaqua.co.id/api/social-media/account/change?media='+media+'&scope='+scope+"&redirect_uri="+redirectUri, {
      headers: headers
    })
    .then(response => {
      window.location = response.data.data.redirect_link
    })
    .catch(errors => {
      console.log(errors)
    })
  }

  const handleIgChangeAccount = () => {
    let token = sessionStorage.getItem("token");
    let headers = {
      'Authorization': token
    }

    let media = 'ig'
    let scope = 'user_profile,user_media'
    let redirectUri = 'https://dashboard.bioaqua.co.id/social-media'

    axios.get('https://api.bioaqua.co.id/api/social-media/account/change?media='+media+'&scope='+scope+"&redirect_uri="+redirectUri, {
      headers: headers
    })
    .then(response => {
      window.location = response.data.data.redirect_link
    })
    .catch(errors => {
      console.log(errors)
    })
  }
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Social Media' user='Admin'/>
        <div className='dashboard-content'>
          <Row>
            <Col><h4 className='content-header'>Embedded Social Media</h4></Col>
          </Row>
        </div>
        <div className='row-data dashboard-social-media-row'>
          <Row>
            <Col>
              <div className='dashboard-social-media-content'>
              <h3>TikTok</h3>
              {
                tiktok ? (
                  <>
                    <p className='dashboard-social-media-account-name'>{tiktok.account_name}</p>
                    <p>Expired: {tiktok.refresh_expires_in}</p>
                    <Button variant='secondary' onClick={handleTiktokChangeAccount}>Change to Another Account</Button>
                  </>
                ) : (
                  <>
                    <Button variant='secondary' onClick={handleTiktokChangeAccount}>Connect Account</Button>
                  </>
                )
              }
              </div>
            </Col>
            <Col>
              <div className='dashboard-social-media-content'>
              <h3>Instagram</h3>
              {
                ig ? (
                  <>
                    <p className='dashboard-social-media-account-name'>{ig.account_name}</p>
                    <p>Expired: {ig.refresh_expires_in}</p>
                    <Button variant='secondary' onClick={handleIgChangeAccount}>Change to Another Account</Button>
                  </>
                ) : (
                  <>
                    <Button variant='secondary' onClick={handleIgChangeAccount}>Connect Account</Button>
                  </>
                )
              }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Home;
