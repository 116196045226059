import moment from 'moment'
import {Row, Col, Button, Modal, Container} from 'react-bootstrap'
import React, { useEffect, useState, useMemo } from "react";

import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Pagination from '../../components/pagination'

let PageSize = 8;

const Home = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.bioaqua.co.id/api/users/members`,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setData(response.data);
        setTotal(response.total);
        setCurrentData(response.data.slice(0, PageSize));
        setCurrentPage(1);
      })
      .catch(error => console.log(error));
  }, []);

  useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentData(data.slice(firstPageIndex, lastPageIndex));
  }, [currentPage]);

  const [showDetails, setShowDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState({});
  const handleCloseDetails = () => setShowDetails(false);

  const handleShowDetails = (e) => { 
    var id = e.currentTarget.getAttribute("data-id");
    data.map((user, index) => {
      if(user.id.toString() == id) {
        setDataDetails(user);
      }
    })
    setShowDetails(true);
  }
  
  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        <Header page='Users' user='Admin'/>
        <div className='dashboard-content'>
            <h4 className='content-header'>Registered Users</h4>
        </div>
        <div className='row-data-cust'>
            <div className='data-total-section'>
                <p>Total : {total} Users</p>
            </div>
          <div className='row-header mb-4'>
            <Row>
                <Col md={2}>Name</Col>
                <Col>Email</Col>
                <Col>Created Date</Col>
                <Col md={2}></Col>
            </Row>
          </div>
          <div className='row-list'>
          {currentData.map((cust, index) => (
            <Row key={index} className='mb-3 row-list-per-data'>
              <Col md={2}>{cust.name}</Col>
              <Col>{cust.email}</Col>
              <Col>{moment(cust.created_at).format("D MMM YYYY - h:mm A")}</Col>
              <Col md={2}>
                <Button variant='light' className='row-btn-details' data-id={cust.id} onClick={handleShowDetails}>View Details</Button>
              </Col>
            </Row>
          ))}
          {
            currentData.length <= 0 ? (
                <center>
                    <p>No Users Registered Yet</p>
                </center>
            ): (<></>)
          }
          </div>
        </div>
        <Pagination
          className="pagination-bar pb-4 justify-content-end pe-4"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>

      <Modal show={showDetails} onHide={handleCloseDetails}>
        <Modal.Header closeButton>
          <Modal.Title>User's Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
            dataDetails && Object.keys(dataDetails).length === 0 ? (
              <></>
            ) : (
                <>
                <div className='details-header'>
                    <Row>
                    <Col>
                        <p>Username : </p>
                        </Col>
                    <Col>
                        <p className='details-header-text'>{dataDetails.name}</p>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <p>Email : </p>
                    </Col>
                    <Col>
                        <p className='details-header-text'>{dataDetails.email}</p>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                        <p>Verified Level : </p>
                    </Col>
                    <Col>
                        <p className='details-header-text'>{dataDetails.verified_level == "1" ? 'Needs Verify Email' : dataDetails.verified_level == "2" ? 'Verified Member' : 'Needs to Fill Questioner'}</p>
                    </Col>
                    </Row>
                </div>
                <div className='details-body'>
                    <Container>
                    <h5>User's Questioner</h5>
                    <div className='mt-4'>
                    {
                        dataDetails.questioner.map((questioner, index) => (
                            <>
                                <p>{questioner.question}</p>
                                <p className='details-questioner-answer'>{questioner.answer}</p>
                            </>
                        ))
                    }
                    {
                        dataDetails.questioner.length <= 0 ? (
                            <center>
                                <p>User's Not Fill The Questioners Yet</p>
                            </center>
                        ) : (
                            <></>
                        )
                    }
                    </div>
                    </Container>
                </div>
            </>
            )
        }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
