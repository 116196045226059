import Sidebar from '../../../components/sidebar'
import Header from '../../../components/header'
import {Row, Col, Button, Form} from 'react-bootstrap'

import IconPlus from '../../../assets/icon-plus.svg'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalResponse from '../../../components/modal-response'
import imageCompression from 'browser-image-compression';

import Select, {OnChangeValue} from 'react-select';

import axios from 'axios'

import { EditorState, ContentState, convertToRaw } from 'draft-js'; 
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Edit = () => {
    const [data, setData] = useState({});
    const { id } = useParams();
    const[categories, setCategories] = useState([]);
    const[formType,setFormType] = useState('add');
    const[tags, setTags] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if(id!=null&&id!='undefined'){
            setFormType('edit')
            fetch(
                `https://api.bioaqua.co.id/api/article/post/`+id,
                {
                  method: "GET",
                }
            )
            .then(res => res.json())
            .then(response => {
                setData(response);
                const blocksFromHtml = htmlToDraft(response.content);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const content = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                );
                setEditorState(EditorState.createWithContent(content));
            })
            .catch(error => console.log(error));
        }
        
        fetch(
            `https://api.bioaqua.co.id/api/article/category`,
            {
            method: "GET",
            }
        )
        .then(res => res.json())
        .then(response => {
          setCategories(response);
        })
        .catch(error => console.log(error));

        fetch(
            `https://api.bioaqua.co.id/api/article/tags`,
            {
            method: "GET",
            }
        )
        .then(res => res.json())
        .then(response => {
            let tages = [{} ];
            response.forEach(element => {
              let tag = {
                value: element.id,
                label: element.name
              }
              tages.push(tag)
            })
            setTags(tages);
        })
        .catch(error => console.log(error));
    }, []);

    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
        if(formType=='edit'){
            const updatedFormBody = {[e.target.name] : e.target.value}  
            setData( data => ({
            ...data,
            ...updatedFormBody,
            }));
        }
    }

    const [files, setFiles] = useState([]);

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleUploadImage = async (e) => {
        var base64files = '';
  
        const image = e.target.files[0];
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(image, options);
          var fileUrl = URL.createObjectURL(image)
          setFiles(fileUrl);
  
          base64files = await convertToBase64(compressedFile);
        } catch (error) {
          console.log(error);
        }
        
        setformBody( formBody => ({
          ...formBody,
          ...{image : base64files},
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
      e.preventDefault();
      setSubmitting(true);
      let token = sessionStorage.getItem("token");
      let headers = {
        'Authorization': token
      }

      axios.post('https://api.bioaqua.co.id/api/article/post', formBody, {
        headers: headers
      })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/post';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
            setSubmitting(false);
        })
    };

    const[submitting, setSubmitting] = useState(false);

    const handleSelectTags = (OnChangeValue) => {
        let selectTags = []
        OnChangeValue.forEach((value) => {
            selectTags.push(value.value)
        })
        setformBody( formBody => ({
            ...formBody,
            ...{id_tags : selectTags},
        }));
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setSubmitting(true);

        let token = sessionStorage.getItem("token");
        let headers = {
            'Authorization': token
        }
        
        axios.put('https://api.bioaqua.co.id/api/article/post/'+data.id, formBody, {
            headers: headers
        })
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/article/post';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
            setSubmitting(false);
        })
    }

    const onEditorStateChange = (e) => {
        setEditorState(e)
        let rawContentState = convertToRaw(editorState.getCurrentContent());
        let html = draftToHtml(rawContentState)
        setformBody( formBody => ({
            ...formBody,
            ...{content : html},
        }));
    }

    function uploadImageCallBack(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const dataURL = reader.result;
              const truncatedDataURL = dataURL.substring(10, 30) + "..."; // set the maximum length of the truncated string
              resolve({ data: { link: dataURL } , link : { url : truncatedDataURL} });
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
      }

    return (
        <>
        <Sidebar />
        <div className='main-dashboard'>
            {
                showResponse ? (
                    <ModalResponse event={formType == 'edit' ? ('Posts Edited'):('Posts Added')} response={responseMessage}/>
                ) : (
                    <div></div>
                )
            }
            <Header page={formType == 'edit' ? ('Edit Post'):('Add New Post')} user='Diana Prince'/>
            <Row className='dashboard-content'>
            <Col md={12}>
                <Form onSubmit={formType == 'edit' ? handleUpdate:handleFormSubmit}>
                <Row>
                    <Col>
                    <h4 className='content-header'>{formType == 'edit' ? ('Edit Article Post'):('Add New Article Post')}</h4>
                    </Col>
                    <Col className='content-header-btn-action'>
                    <Button type='submit' variant='warning' className='header-btn-action-change' disabled={submitting}>{submitting ? 'Saving...' : 'Save Changes'}</Button>
                    </Col>
                </Row>
                <div className='content-form'>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Category</Form.Label>
                        <Form.Select aria-label="Default select example" name='id_category' onChange={handleFormChange} value={formType == 'edit' ? (data.id_category):(null)}>
                            <option>Select Category</option>
                            {
                            categories.map((category, index) => (
                                <option value={category.id} key={index}>{category.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Tags</Form.Label>
                            {formType == 'edit'? (
                                <Select
                                    value={data.tags}
                                    name='id_tags'
                                    isMulti
                                    options={tags}
                                    id='tags'
                                    onChange={handleSelectTags}
                                />
                            ) : (
                                <Select
                                    isMulti
                                    options={tags}
                                    name='id_tags'
                                    id='tags'
                                    onChange={handleSelectTags}
                                />
                            )}
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Title</Form.Label>
                        <Form.Control type="text" placeholder="Add Posts Title" name='title' onChange={handleFormChange} value={formType == 'edit' ? (data.title):(null)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Subtitle</Form.Label>
                        <Form.Control type="text" placeholder="Add Posts Subtitle" name='subtitle' onChange={handleFormChange} value={formType == 'edit' ? (data.subtitle):(null)}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Upload Banner<span>(Recommended Resolution: 1920x1080; Max File Size: 2MB)</span></Form.Label>
                        <label htmlFor='form-image' className='label-home-banner'>
                            {
                                files != '' ? (
                                    <img src={files} alt='...' className='label-home-banner-img'/>
                                )
                                : formType == 'edit' ? (
                                    <img src={'https://api.bioaqua.co.id/article/'+data.image} alt='...' className='label-home-banner-img'/>
                                ):(
                                    <img src={IconPlus} className='label-home-banner-icon'/>
                                )
                            }
                        </label>
                        <input id='form-image' type="file" hidden onChange={handleUploadImage}/>
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                        <Form.Label className='content-form-label'>Content</Form.Label>
                        <Editor 
                            editorStyle={{ border: "1px solid #e2e2e2", overflow: "scroll", height: "500px", overflowX:"hidden" }} 
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={
                                {
                                    image:{
                                        urlEnabled: true,
                                        uploadEnabled: true,
                                        previewImage: true,
                                        uploadCallback: (file) => uploadImageCallBack(file),
                                        alignmentEnabled: true,
                                        defaultSize: {
                                            height: 'auto',
                                            width: 'auto',
                                        },
                                        alt: { present: false, mandatory: false },
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
                                    }
                                }
                            }
                        />
                    </Form.Group>
                </div>
                </Form>
            </Col>
            </Row>
        </div>
        </>
    );
}

export default Edit;