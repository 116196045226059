import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import ModalResponse from '../../components/modal-response'
import {Row, Col, Button, Form} from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios'

const Add = () => {
    const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
      e.preventDefault();

      let token = sessionStorage.getItem("token");

      let headers = {
        'Authorization': token
      }

      axios.post('https://api.bioaqua.co.id/api/store', formBody, {
        headers: headers
      })
      .then(response => {
          setShowResponse(true);
          setResponseMessage('success');
          window.setTimeout(function() {
              window.location = '/stores';
          }, 1000);
      })
      .catch(errors => {
          setShowResponse(true);
          setResponseMessage('error');
      })
    }

    return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Stores Added' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Store Locations' user='Admin'/>
        <Row className='dashboard-content'>
          <Col md={12}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Add Store Location</h4>
                </Col>
                <Col className='content-header-btn-action'>
                  <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Add Store Name" name='name' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Address</Form.Label>
                  <Form.Control as="textarea" rows={5} name='address' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Latitude</Form.Label>
                  <Form.Control type="text" placeholder="Add Store Latitude Location" name='latitude' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Longitude</Form.Label>
                  <Form.Control type="text" placeholder="Add Store Longitude Location" name='longitude' onChange={handleFormChange}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Add;