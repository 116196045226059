import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import Status from '../../components/status'
import {Row, Col, Button, Form} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ModalResponse from '../../components/modal-response'

const Edit = () => {
  const [member, setMember] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `https://api.bioaqua.co.id/api/users/internal/`+id,
      {
        method: "GET",
      }
      )
      .then(res => res.json())
      .then(response => {
        setMember(response);
      })
      .catch(error => console.log(error));
  }, []);

  const [formBody, setformBody] = useState({});

    const handleFormChange = (e) => {
        const updatedFormBody = {[e.target.name] : e.target.value}  
        setMember( category => ({
          ...category,
          ...updatedFormBody,
        }));
        setformBody( formBody => ({
            ...formBody,
            ...updatedFormBody,
        }));
    }

    const [showResponse, setShowResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios.put('https://api.bioaqua.co.id/api/users/internal/'+id, formBody)
        .then(response => {
            setShowResponse(true);
            setResponseMessage('success');
            window.setTimeout(function() {
                window.location = '/member';
            }, 1000);
        })
        .catch(errors => {
            setShowResponse(true);
            setResponseMessage('error');
        })
    }

    const [passwordMatch, setPasswordMatch] = useState(false);

    const handleConfirmationPassword = (e) => {
        if(e.target.value === document.getElementById("password-field").value) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    };

  return (
    <>
      <Sidebar />
      <div className='main-dashboard'>
        {
            showResponse ? (
                <ModalResponse event='Category Updated' response={responseMessage}/>
            ) : (
                <div></div>
            )
        }
        <Header page='Member' user='Diana Prince'/>
        <Row className='dashboard-content'>
          <Col md={9}>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Col>
                  <h4 className='content-header'>Edit Member</h4>
                </Col>
                <Col className='content-header-btn-action'>
                    {
                        passwordMatch ? (
                            <Button type='submit' variant='warning' className='header-btn-action-change'>Save Changes</Button>
                        ) : (
                            <Button type='submit' variant='warning' className='header-btn-action-change' disabled>Save Changes</Button>
                        )
                    }
                </Col>
              </Row>
              <div className='content-form'>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Name</Form.Label>
                  <Form.Control type="text" placeholder="Input Your Name" value={member.name} name='name' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Email</Form.Label>
                  <Form.Control type="text" placeholder="Input Your Email" value={member.email} name='email' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Password<span>(Don't Fill if you didn't want to change password)</span></Form.Label>
                  <Form.Control id='password-field' type="password" placeholder="Input Your Password" name='password' onChange={handleFormChange}/>
                </Form.Group>
                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                  <Form.Label className='content-form-label'>Confirmation Password<span>(Don't Fill if you didn't want to change password)</span></Form.Label>
                  <Form.Control type="password" placeholder="Input Your Confirmation Password" onChange={handleConfirmationPassword}/>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md={3}>
            <Status created={member.created_at} updated={member.updated_at} dataid={member.id} updatedby={member.updated_by} ispublished='1'/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Edit;